<template>
  <div class="iss-main">
    <a-tabs tab-position="left">
      <a-tab-pane key="base" tab="基本设置">
        <div class="iss-title">基本设置</div>
        <a-form
          layout="vertical"
          ref="formRef"
          :model="form"
          :rules="rules"
          :validate-trigger="['change', 'blur']"
        >
          <a-row :gutter="20">
            <a-col :span="14">
              <a-form-item name="name" label="名称">
                <a-input v-model:value="form.name" placeholder="请输入名称" />
              </a-form-item>
              <a-form-item name="sex" label="性别">
                <a-radio-group v-model:value="form.sex.code">
                  <a-radio value="M">男</a-radio>
                  <a-radio value="W">女</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item name="workDescribe" label="个人简介">
                <a-textarea
                  v-model:value="form.workDescribe"
                  show-count
                  :maxlength="100"
                  placeholder="请输入个人简介"
                />
              </a-form-item>
              <a-form-item>
                <a-button type="primary" @click="handleClickBySave">
                  保存
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :span="10">
              <a-form-item>
                <iss-avatar v-model:value="form.avatar" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="saft" tab="安全设置">
        <div class="iss-title">安全设置</div>
        <a-list :dataSource="dataSource">
          <template #renderItem="{ item }">
            <a-list-item>
              <template #actions>
                <a @click="handleClickByUpdate(item.key)">修改</a>
              </template>
              <a-list-item-meta
                :title="item.title"
                :description="item.description"
              />
            </a-list-item>
          </template>
        </a-list>
      </a-tab-pane>
    </a-tabs>
  </div>
  <a-modal v-model:visible="visible" title="修改" centered @ok="handleOk">
    <a-form
      v-if="visible === 'password'"
      ref="passwordRef"
      :model="passwordForm"
      :rules="passwordRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item name="oldPassword" label="旧密码">
        <a-input-password
          v-model:value="passwordForm.oldPassword"
          placeholder="请输入旧密码"
        />
      </a-form-item>
      <a-form-item name="password" label="新密码">
        <a-input-password
          v-model:value="passwordForm.password"
          placeholder="请输入新密码"
        />
      </a-form-item>
      <a-form-item name="confirmPassword" label="确认密码">
        <a-input-password
          v-model:value="passwordForm.confirmPassword"
          placeholder="请输入确认密码"
        />
      </a-form-item>
    </a-form>
    <a-form
      v-else
      ref="safeRef"
      :model="safeForm"
      :rules="safeRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item
        name="account"
        :label="visible === 'mobile' ? '新手机号码' : '新邮箱地址'"
      >
        <a-input
          autocomplete="off"
          v-model:value="safeForm.account"
          :placeholder="
            visible === 'mobile' ? '请输入新手机号码' : '请输入新邮箱地址'
          "
        />
      </a-form-item>
      <a-form-item name="code" label="验证码">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input v-model:value="safeForm.code" placeholder="请输入验证码" />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode"
            >
              {{ btn.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { Col, Form, List, Radio, Row, Tabs } from 'ant-design-vue';
import IssAvatar from '@/components/avatar';
import { desensitization } from '@/utils';
import { validMobile, validEmail } from '@/utils/validation';
import loginApi from '@/api/login';
import personalApi from '@/api/personal';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    AList: List,
    AListItem: List.Item,
    AListItemMeta: List.Item.Meta,
    IssAvatar,
  },
  data() {
    const required = { required: true, message: '不能为空' };
    return {
      form: { sex: {} },
      rules: { name: required },
      passwordForm: { oldPassword: '', password: '', confirmPassword: '' },
      passwordRules: {
        oldPassword: required,
        password: [
          required,
          {
            pattern:
              /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
            message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
          },
        ],
        confirmPassword: [
          required,
          {
            validator: (rule, value) =>
              value === this.passwordForm.password
                ? Promise.resolve()
                : Promise.reject('两次输入密码不匹配'),
          },
        ],
      },
      safeForm: { account: '', code: '' },
      safeRules: {
        account: [
          required,
          {
            validator: (rule, value) => {
              if (this.visible === 'mobile') {
                return validMobile(value)
                  ? Promise.resolve()
                  : Promise.reject('请输入合法的手机号码');
              }
              return validEmail(value)
                ? Promise.resolve()
                : Promise.reject('请输入合法的邮箱地址');
            },
          },
        ],
        code: required,
      },
      dataSource: [],
      timer: null,
      visible: false,
      showVal: null,
      confirmLoading: false,
      btn: {
        disabled: false,
        title: '获取验证码',
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      personalApi
        .getItemById(this.$route.path, this.$store.state.account.user.userId)
        .then(data => {
          this.form = data;
          this.dataSource = [
            {
              key: 'password',
              title: '账号密码',
              description: '当前密码强度：强',
            },
            {
              key: 'mobile',
              title: '密保手机',
              description: `已绑定手机：${desensitization(data.mobile)}`,
            },
            {
              key: 'email',
              title: '备用邮箱',
              description: `已绑定邮箱：${desensitization(data.email)}`,
            },
          ];
        });
    },
    handleClickBySave() {
      this.$refs.formRef.validate().then(() => {
        personalApi
          .update('personal:update', this.form)
          .then(() => location.reload());
      });
    },
    handleClickByUpdate(key) {
      if (this.showVal !== key && this.showVal !== 'password') {
        this.btn = { disabled: false, title: '获取验证码' };
        clearInterval(this.timer);
        this.timer = null;
      }
      this.visible = key;
      this.showVal = key;
      switch (key) {
        case 'password':
          this.passwordForm = {
            oldPassword: '',
            password: '',
            confirmPassword: '',
          };
          break;
        case 'mobile':
        case 'email':
          this.safeForm = { account: '', code: '' };
          this.$refs.safeRef?.resetFields();
          break;
        default:
          break;
      }
    },
    handleGetCode() {
      this.$refs.safeRef.validateFields('account').then(() => {
        this.btn = { disabled: true, title: '重新获取(60s)' };
        let count = 60;
        this.timer = setInterval(() => {
          if (count === 1) {
            this.btn = { disabled: false, title: '获取验证码' };
            clearInterval(this.timer);
            this.timer = null;
          } else {
            count--;
            this.btn.title = `重新获取(${count}s)`;
          }
        }, 1000);
        loginApi.getCode(this.safeForm.account).then(() => {
          this.$message.success('验证码已发送，请注意查收');
        });
      });
    },
    handleOk() {
      switch (this.visible) {
        case 'password':
          this.handlePassword();
          break;
        case 'mobile':
        case 'email':
          this.handleSafe();
          break;
        default:
          break;
      }
    },
    handlePassword() {
      this.$refs.passwordRef.validate().then(() => {
        this.confirmLoading = true;
        personalApi
          .updatePassword({
            ...this.passwordForm,
            id: this.form.id,
          })
          .then(() => {
            localStorage.clear();
            location.reload();
          })
          .finally(() => {
            this.confirmLoading = false;
          });
      });
    },
    handleSafe() {
      this.$refs.safeRef.validate().then(() => {
        this.confirmLoading = true;
        loginApi
          .verifyCode(this.safeForm)
          .then(data => {
            if (data) {
              this.form[this.visible] = this.safeForm.account;
              personalApi
                .update('personal:update', this.form)
                .then(() => {
                  localStorage.clear();
                  location.reload();
                })
                .finally(() => {
                  this.confirmLoading = false;
                });
            } else {
              this.confirmLoading = false;
              this.$message.error('验证失败，请重新获取验证码');
            }
          })
          .catch(() => (this.confirmLoading = false));
      });
    },
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.iss-title {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 12px;
}
</style>
